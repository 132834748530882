import React, { useEffect, useState } from "react";
import "./votinginner.scss";
import Footer from "../../components/Footer/Footer";
import { closeSVG, imagineArrow, termsandconditionsPDF } from "../../images";
import { useNavigate, useParams, Link } from "react-router-dom";
import _ from "lodash";
import config from "../../config/config";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Joi from "joi";
import {
  finalist2024URL,
  privacyandcookiepolicyroute,
  votingURL,
} from "../../helpers/constant-words";
import { votingArr } from "../../data/votingData";
import Loader from "../../components/Loader/Loader";

const VotingInner = () => {
  const [backgroundColor, setBackgroundColor] = useState("#3172A9");
  const [ctaArrowColor, setCtaArrowColor] = useState();
  const [pageclass, setPageClass] = useState("");
  const [voteCtaclass, setVoteCtaClass] = useState("");
  let { slug } = useParams();
  const [voting, setVoting] = useState([]);
  const [data, setData] = useState(votingArr[0]);
  const [category, setCategory] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalColor, setModalColor] = useState("#57AC91");
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataloading, setDataLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });
  const [term1, setTerm1] = useState(false);
  const [brand, setBrand] = useState({});

  let navigate = useNavigate();
  const themes = {
    grow: "#3172a9",
    imagine: "#c774a0",
    create: "#fc9500",
  };
  const [isHover, setIsHover] = useState(false);
  const [isIndex, setIsIndex] = useState(0);

  const image_url = config.api_url + "finalists-2024/"; //with trailing slash
  const base_url = config.api_url + "2024/finalists";

  const currentVoter = helper.getVoter();

  const today = moment().utc();

  const fetchData = async (slug) => {
    setDataLoading(true);
    const { data } = await http.get(`${base_url}/${slug}`);

    if (!data) {
      navigate("/404");
    } else {
      // const category = getCategory(data?.category);
      // setCategory(categoryData);
      setData(data);
      console.log(data);

      setBackgroundColor(themes[data.category]);
      // setCategory(category);
      // setPageClass(themes[category.templateColor]);
      // setBackgroundColor(category.templateColor);
      // setCtaArrowColor(category.arrowColor);
      // setVoteCtaClass(themes[category.templateColor]);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchData(slug);
  }, [slug]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleterm1 = (e) => {
    setTerm1((current) => !current);
  };

  const handleVoteDelete = async (brand) => {
    setLoading(true);
    // console.log("handle delete");
    const body = {
      email: currentVoter.email,
      brand: brand._id,
    };
    const data = await http.post(base_url + "/vote/delete", body);
    if (data) {
      toast.success("Unvoted Successfully");
      fetchData(slug);
    }

    setLoading(false);
  };

  const handleVoteCreate = async (brand) => {
    setLoading(true);
    if (currentVoter) {
      const body = {
        email: currentVoter.email,
        category: brand.category,
        brand: brand._id,
      };
      const data = await http.post(base_url + "/vote/create", body);

      if (data) {
        toast.success("Voted Successfully");
        fetchData(slug);
      }
    } else {
      // setModalColor(category.templateColor);
      setBrand(brand);
      setModalShow(true);
    }
    setLoading(false);
  };

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (!term1) {
      errors["term1"] = "REQUIRED";
    }

    return errors ? errors : null;
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name == "email"
            ? e.target.value.toLowerCase()
            : e.target.value,
      };
    });
  };

  const handleotpverify = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);
    // let form_values = _.omit(values, ["password"]);
    const data = await http.customPost(base_url + "/vote/otp/verify", {
      ...values,

      category: brand.category,
      brand: brand._id,
    });

    if (data?.status === 400) {
      toast.error(data.message);
    }

    if (data?.status === 409) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_voter_auth_key, token);

      setModalShow(false);
      toast.error(data.message);
      // fetchData(slug);
    }

    if (data?.status === 200) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_voter_auth_key, token);

      setModalShow(false);
      toast.success("Voted Successfully");
      fetchData(slug);
    }
    setLoading(false);
  };

  let voteButton;

  const voterFound = _.find(_.reverse(_.sortBy(data?.voters, ["createdAt"])), {
    email: currentVoter?.email,
  });
  // console.log(data.name, voterFound);
  // if voter found
  if (voterFound) {
    // if voter date is today's date
    if (today.isSame(voterFound.createdAt, "D")) {
      voteButton = (
        <>
          {/* <div className="voting_btn">
            <Button
              className={`project-button   ${pageclass}`}
              onClick={() => {
                handleVoteDelete(data);
              }}
            >
              Unvote
            </Button>
          </div> */}

          <div className="voting_btn">
            <div
              className="project-button"
              onClick={() => {
                handleVoteDelete(data);
              }}
              style={{ "--hover-color": backgroundColor }}
            >
              Unvote
            </div>
          </div>
        </>
      );
    }
    // if voter date is not today's date
    else {
      voteButton = (
        <>
          <div className="voting_btn">
            <div
              className="project-button"
              onClick={() => {
                handleVoteCreate(data);
              }}
              style={{ "--hover-color": backgroundColor }}
            >
              Vote Now
            </div>
          </div>
        </>
      );
    }
  }
  //if voter not found
  else {
    voteButton = (
      <>
        <div className="voting_btn">
          <div
            className="project-button"
            onClick={() => {
              handleVoteCreate(data);
            }}
            style={{ "--hover-color": backgroundColor }}
          >
            Vote Now
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  if (dataloading) {
    return <Loader />;
  }

  return (
    <>
      {dataloading ? (
        <Loader />
      ) : (
        <>
          <section className="voting_imagine_sec2">
            <Link to={`${finalist2024URL}/#${data.id}`}>
              <img
                width={68}
                height={68}
                src={closeSVG}
                alt="close"
                className="close_icon"
                // onClick={handleBackButtonClick}
              />
            </Link>
            <div
              className="row imagine_row"
              style={{ background: backgroundColor }}
            >
              <div className="col-sm-6 col-md-6 imagine_col order-sm-2 order-md-2">
                <div className="imagine_finalist_image">
                  <img
                    // src={image_url + data.heroImg}
                    src={image_url + data.heroImg}
                    width={709.33}
                    height={867}
                    className="finalist_img"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 imagine_col order-sm-1 order-md-1">
                <div className="imagine_finalist_details">
                  <h4
                    className="finalist_name"
                    dangerouslySetInnerHTML={{ __html: data.company }}
                  ></h4>
                  <p
                    className="finalist_desg"
                    dangerouslySetInnerHTML={{ __html: data.name }}
                  ></p>
                  <p
                    className="finalist_desc"
                    dangerouslySetInnerHTML={{ __html: data.desc }}
                  ></p>
                  {data.web !== "" || data.insta !== "" ? (
                    <div className="finalist_btn_container">
                      {data.web !== "" ? (
                        <div className="website_btn_wrapper">
                          <a
                            href={data.web}
                            className="external-button"
                            rel="noreferrer"
                            target="_blank"
                          >
                            WEBSITE
                          </a>
                        </div>
                      ) : null}
                      {data.insta !== "" ? (
                        <div className="website_btn_wrapper">
                          <a
                            href={data.insta}
                            className="external-button"
                            rel="noreferrer"
                            target="_blank"
                          >
                            INSTAGRAM
                          </a>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="vote_btn_wrapper">{voteButton}</div>
                </div>
              </div>
            </div>
          </section>

          <Modal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <div className="emailid_modal">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <h4 className="email_heading">VOTE NOW</h4>
                <p className="desc">
                  PLEASE ENTER YOUR EMAIL ADDRESS TO CONFIRM YOUR VOTE
                </p>
                <form onSubmit={handleotpverify}>
                  <div className="form_wrapper">
                    <div className="form_field_container">
                      <input
                        type="text"
                        className="form_input"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {formError.email && (
                        <p className="error_message">{formError.email}</p>
                      )}
                    </div>
                    <div className="consent_checkbox_wrapper">
                      <input
                        type="checkbox"
                        id="updateConsent"
                        name="updateConsent"
                        className="consent_input"
                        onClick={handleterm1}
                      />
                      <label for="updateConsent" className="consent_label">
                        {/* <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span> */}
                        <span>
                          {" "}
                          By checking this box and clicking Sign Up, I agree to
                          the{" "}
                          <Link
                            to={termsandconditionsPDF}
                            target="_blank"
                            rel="noreferrers"
                          >
                            {" "}
                            Terms and Conditions
                          </Link>{" "}
                          and{" "}
                          <Link
                            to={privacyandcookiepolicyroute}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </Link>
                          .
                        </span>
                      </label>
                      {formError.term1 && (
                        <p className="error_message">
                          <strong>{formError.term1}</strong>
                        </p>
                      )}
                    </div>
                    <div className="btn_wrapper">
                      <button
                        className="form_submit_btn  project-button"
                        type="submit"
                      >
                        {loading ? "Loading.." : "SUBMIT"}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </div>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
};

export default VotingInner;
