import { termsandconditionsPDF } from "../images";

export const APPLY_BUTTON_TEXT = "apply";
export const LEARN_BUTTON_TEXT = "Learn More";
export const FADE_DELAY = 300;
export const instaLink =
  "https://www.instagram.com/beautyandyouawards?igsh=MzRlODBiNWFlZA==";
export const linkedinLink =
  "https://www.linkedin.com/company/beauty-and-you/mycompany/?viewAsMember=true";

// routes name
export const homepage = "/";
export const aboutUsURL = "/about-us";
export const partnerURL = "/partner";
export const awardsURL = "/prizes";
export const faqURL = "/faq";
export const application = "/application-criteria";
export const judges = "/judges";
export const contactUs = "/contact-us";
export const masterClass = "/master-class";
export const newmasterInnerURL = "/master-class/:slug";
export const masterClassSignUp = "/master-class/sign-up";
export const masterClassLogin = "/master-class/login";
export const accountLogin = "/account/login";
export const accountReset = "/account/reset";
export const accountSignUp = "/account/sign-up";
export const accountSignUpThankyou = "account/sign-up/thankyou";
export const accountRegister = "/account/register";
export const accountRegisterThankyou = "/account/register/grow-thankyou";
export const accountImagineThankYouRoute = "/account/register/imagine-thankyou";
export const ThankyouURL = "/account/register/thankyou";
export const comingSoon = "/coming-soon";
export const pageNotFound = "/404";
export const termsAndConditionsRoute = termsandconditionsPDF;
export const privacyandcookiepolicyroute = "/privacy-and-policy";
export const LeadershipBoardURL = "/community-voting";
export const FinalistsnewURL = "/finalists";
export const prevWinnersURL = "/2022-winners";
export const twentThreeWinnersURL = "/2023-winners";
export const pressURL = "/press";
export const applicationcriteria = "/application-criteria";
export const finalistURL = "/finalist";
export const imagineURL = "/finalist/:categorySlug/:finalistSlug";
export const growURL = "/grow";
export const breakURL = "/break";
export const createURL = "/create";
export const votingURL = "/community-voting";
export const votingimagineURL = "/community-voting/:categorySlug/:votingSlug";
export const votinggrowURL = "/community-voting-grow";
export const votingcreateURL = "/community-voting-create";
export const meetWinnersURL = "/meet-the-winners";
export const awardsEveningURL = "/awards-evening";
export const luminariesURL = "/luminaries";
export const businessOfFashionURL = "/business-of-fashion";
export const exhibitionURL = "/exhibition";
export const finalist2024URL = "/2024-finalist-voting";
