import React, { useEffect, useRef, useState } from "react";
import "./voting.scss";
import { Link, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  aboutBanner,
  finalist2024Banner,
  images,
  mbAboutBanner,
  mbFinalist2024Banner,
} from "../../images";
import FsLightbox from "fslightbox-react";
import { votingArr } from "../../data/votingData";
import Votingemail from "../../components/Votingemail/Votingemail";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import _ from "lodash";
import config from "../../config/config";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import moment from "moment";
import { toast } from "react-toastify";
import Joi from "joi";
import { Modal } from "react-bootstrap";
import { termsandconditionsPDF } from "../../images";
import {
  finalist2024URL,
  privacyandcookiepolicyroute,
} from "../../helpers/constant-words";
import { findCategoryBySlug } from "../../data/votingDataV2";

const Voting = () => {
  const { width: windowWidth } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState({});
  const [term1, setTerm1] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [modalColor] = useState("#ffffff");
  const growRefs = useRef([]);
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
  });

  const [imagineData, setimagineData] = useState([]);
  const [growData, setgrowData] = useState([]);
  const [createData, setcreateData] = useState([]);





  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const setGrowRef = (element, index) => {
    growRefs.current[index] = element;
  };

  const base_route = finalist2024URL;

  const image_url = config.api_url + "finalists-2024/"; //with trailing slash
  const base_url = config.api_url + "2024/finalists";

  const currentVoter = helper.getVoter();

  const today = moment().utc();

  const fetchDatas = async () => {
    setDataLoading(true);

    const { data } = await http.get(base_url);

    if (data) {
      // if(isShuffle=="yes"){
      setimagineData(_.filter(data, ["category", "imagine"]));
      setgrowData(_.filter(data, ["category", "grow"]));
      setcreateData(_.filter(data, ["category", "create"]));
      // }
      // else{
      //   setimagineData(_.filter(data, ["category", "imagine"]));
      //   setgrowData(_.filter(data, ["category", "grow"]));
      // }

      // const hash = window.location.hash;
      // console.log(typeof hash);

      // if (hash) {
      //   console.log("has function running");
      //   const targetId = hash.substring(1);
      //   const targetSection = document.getElementById(targetId);
      //   console.log("outside timeout", targetId, targetSection);
      //   if (targetSection) {
      //     setTimeout(() => {
      //       const offset = 120;
      //       const targetPosition =
      //         targetSection.getBoundingClientRect().top +
      //         window.scrollY -
      //         offset;
      //       window.scrollTo({ top: targetPosition, behavior: "smooth" });
      //       // targetSection.scrollIntoView({ behavior: "smooth", block: "center" });
      //     }, 0);
      //     navigate(finalist2024URL, { replace: true });
      //     console.log("inside timeout", targetId, targetSection);
      //   }
      // }
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchDatas();

    const hash = window.location.hash;

    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleterm1 = (e) => {
    setTerm1((current) => !current);
  };

  const handleVoteDelete = async (brand) => {
    setLoading(true);
    console.log("handle delete");
    const body = {
      email: currentVoter.email,
      brand: brand._id,
    };
    const data = await http.post(base_url + "/vote/delete", body);
    if (data) {
      toast.success("Unvoted Successfully");
      fetchDatas();
    }

    setLoading(false);
  };
  const handleVoteCreate = async (brand) => {
    setLoading(true);
    if (currentVoter) {
      const body = {
        email: currentVoter.email,
        category: brand.category,
        brand: brand._id,
      };
      const data = await http.post(base_url + "/vote/create", body);

      if (data) {
        toast.success("Voted Successfully");
        fetchDatas();
      }
    } else {
      const category = findCategoryBySlug(brand.category);

      // setModalColor(category.templateColor);
      setBrand(brand);
      setModalShow(true);
    }
    setLoading(false);
  };

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (!term1) {
      errors["term1"] = "REQUIRED";
    }

    return errors ? errors : null;
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name == "email"
            ? e.target.value.toLowerCase()
            : e.target.value,
      };
    });
  };

  const handleotpverify = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);
    // let form_values = _.omit(values, ["password"]);
    const data = await http.customPost(base_url + "/vote/otp/verify", {
      ...values,

      category: brand.category,
      brand: brand._id,
    });

    if (data?.status === 400) {
      toast.error(data.message);
    }

    if (data?.status === 409) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_voter_auth_key, token);

      setModalShow(false);
      toast.error(data.message);
      // fetchDatas();
    }

    if (data?.status === 200) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_voter_auth_key, token);

      setModalShow(false);
      toast.success("Voted Successfully");
      fetchDatas();
    }
    setLoading(false);
  };

  const growList = growData.map((item, i) => {
    let voteButton;
    const voterFound = _.find(_.reverse(_.sortBy(item.voters, ["createdAt"])), {
      email: currentVoter?.email,
    });
    // console.log(item.name, voterFound);
    // if voter found
    if (voterFound) {
      // if voter date is today's date
      if (today.isSame(voterFound.createdAt, "D")) {
        voteButton = (
          <>
            <div className="voting_btn">
              <div
                className={`vote_cta `}
                onClick={() => {
                  handleVoteDelete(item);
                }}
              >
                Unvote
              </div>
            </div>
          </>
        );
      }
      // if voter date is not today's date
      else {
        voteButton = (
          // <>
          //   <button
          //     className={`project-button disabled  `}
          //     disabled={true}
          //   >
          //     Voted
          //   </button>
          // </>
          <>
            <div className="voting_btn">
              <div
                className={`vote_cta `}
                onClick={() => {
                  handleVoteCreate(item);
                }}
              >
                Vote Now
              </div>
            </div>
          </>
        );
      }
    }
    //if voter not found
    else {
      voteButton = (
        <>
          <div className="voting_btn">
            <div
              className={`vote_cta `}
              onClick={() => {
                handleVoteCreate(item);
              }}
            >
              Vote Now
            </div>
          </div>
        </>
      );
    }
    return (
      <React.Fragment key={`grow-${i}`}>
        <div
          className="col-sm-6 col-md-4 col-lg-4 grow_col"
          data-slug={item.slug}
          id={item.id}
        >
          <div className="grow_wrapper">
            <Link
              to={`${base_route}/${item.slug}`}
              // onClick={() => handleClick(item.slug, "growList")}
              className="img_link"
            >
              <img
                width={350}
                height={420}
                src={image_url + item.img}
                alt={item.name}
                className="grow_img"
              />
            </Link>
            <div className="image_content">
              <div className="company_name_wrap">
                <h5 className="grow_company">{item.company}</h5>
                <h6 className="grow_name">{item.name}</h6>
              </div>
              <div className="grow_info">
                <Link
                  to={`${base_route}/${item.slug}`}
                  // onClick={() => handleClick(item.slug, "growList")}
                  className="grow_link"
                >
                  INFORMATION
                </Link>
                <span
                  className="grow_link"
                  onClick={() => handleWatchClick(item.watch)}
                >
                  WATCH NOW
                </span>
                {voteButton}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  const imagineList = imagineData.map((item, i) => {
    let voteButton;
    const voterFound = _.find(_.reverse(_.sortBy(item.voters, ["createdAt"])), {
      email: currentVoter?.email,
    });
    // console.log(item.name, voterFound);
    // if voter found
    if (voterFound) {
      // if voter date is today's date
      if (today.isSame(voterFound.createdAt, "D")) {
        voteButton = (
          <>
            <div className="voting_btn">
              <div
                className={`vote_cta `}
                onClick={() => {
                  handleVoteDelete(item);
                }}
              >
                Unvote
              </div>
            </div>
          </>
        );
      }
      // if voter date is not today's date
      else {
        voteButton = (
          // <>
          //   <button
          //     className={`project-button disabled  `}
          //     disabled={true}
          //   >
          //     Voted
          //   </button>
          // </>
          <>
            <div className="voting_btn">
              <div
                className={`vote_cta `}
                onClick={() => {
                  handleVoteCreate(item);
                }}
              >
                Vote Now
              </div>
            </div>
          </>
        );
      }
    }
    //if voter not found
    else {
      voteButton = (
        <>
          <div className="voting_btn">
            <div
              className={`vote_cta `}
              onClick={() => {
                handleVoteCreate(item);
              }}
            >
              Vote Now
            </div>
          </div>
        </>
      );
    }
    return (
      <React.Fragment key={`imagine-${i}`}>
        <div
          className="col-sm-6 col-md-4 col-lg-4 imagine_col"
          data-slug={item.slug}
          id={item.id}
        >
          <div className="imagine_wrapper">
            <Link
              to={`${base_route}/${item.slug}`}
              // onClick={() => handleClick(item.slug, "imagineList")}
              className="img_link"
            >
              <img
                width={350}
                height={420}
                src={image_url + item.img}
                alt={item.name}
                className="imagine_img"
              />
            </Link>
            <div className="image_content">
              <div className="company_name_wrap">
                <h5 className="imagine_company">{item.company}</h5>
                <h6 className="imagine_name">{item.name}</h6>
              </div>
              <div className="imagine_info">
                <Link
                  to={`${base_route}/${item.slug}`}
                  // onClick={() => handleClick(item.slug, "imagineList")}
                  className="imagine_link"
                >
                  INFORMATION
                </Link>
                <span
                  className="imagine_link"
                  onClick={() => handleWatchClick(item.watch)}
                >
                  WATCH NOW
                </span>
                {voteButton}
                {/* <Votingemail emailText="Vote Now" modalColor="#ffffff" /> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  const createList = createData.map((item, i) => {
    let voteButton;
    const voterFound = _.find(_.reverse(_.sortBy(item.voters, ["createdAt"])), {
      email: currentVoter?.email,
    });
    // console.log(item.name, voterFound);
    // if voter found
    if (voterFound) {
      // if voter date is today's date
      if (today.isSame(voterFound.createdAt, "D")) {
        voteButton = (
          <>
            <div className="voting_btn">
              <div
                className={`vote_cta `}
                onClick={() => {
                  handleVoteDelete(item);
                }}
              >
                Unvote
              </div>
            </div>
          </>
        );
      }
      // if voter date is not today's date
      else {
        voteButton = (
          // <>
          //   <button
          //     className={`project-button disabled  `}
          //     disabled={true}
          //   >
          //     Voted
          //   </button>
          // </>
          <>
            <div className="voting_btn">
              <div
                className={`vote_cta `}
                onClick={() => {
                  handleVoteCreate(item);
                }}
              >
                Vote Now
              </div>
            </div>
          </>
        );
      }
    }
    //if voter not found
    else {
      voteButton = (
        <>
          <div className="voting_btn">
            <div
              className={`vote_cta `}
              onClick={() => {
                handleVoteCreate(item);
              }}
            >
              Vote Now
            </div>
          </div>
        </>
      );
    }
    return (
      <React.Fragment key={`create-${i}`}>
        <div
          className="col-sm-6 col-md-4 col-lg-4 create_col"
          data-slug={item.slug}
          id={item.id}
        >
          <div className="create_wrapper">
            <Link
              to={`${base_route}/${item.slug}`}
              // onClick={() => handleClick(item.slug, "createList")}
              className="img_link"
            >
              <img
                width={350}
                height={420}
                src={image_url + item.img}
                alt={item.name}
                className="create_img"
              />
            </Link>
            <div className="image_content">
              <div className="company_name_wrap">
                <h5 className="create_company">{item.company}</h5>
                <h6 className="create_name">{item.name}</h6>
              </div>
              <div className="create_info">
                <Link
                  to={`${base_route}/${item.slug}`}
                  // onClick={() => handleClick(item.slug, "createList")}
                  className="create_link"
                >
                  INFORMATION
                </Link>
                <span
                  className="create_link"
                  onClick={() => handleWatchClick(item.watch)}
                >
                  WATCH NOW
                </span>
                {voteButton}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });


  useEffect(() => {
    const hash = window.location.hash;
      console.log(typeof hash);

      if (hash) {
        console.log("has function running");
        const targetId = hash.substring(1);
        const targetSection = document.getElementById(targetId);
        console.log("outside timeout", targetId, targetSection);
        if (targetSection) {
          setTimeout(() => {
            const offset = 120;
            const targetPosition =
              targetSection.getBoundingClientRect().top +
              window.scrollY -
              offset;
            window.scrollTo({ top: targetPosition, behavior: "smooth" });
            // targetSection.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 0);
          navigate(finalist2024URL, { replace: true });
          console.log("inside timeout", targetId, targetSection);
        }
      }
  }, [growList]);

  // useEffect(() => {
  //   const savedSlug = localStorage.getItem("scrollBoxSlug");
  //   const savedList = localStorage.getItem("scrollBoxList");

  //   if (savedSlug && savedList) {
  //     const boxElement = document.querySelector(`[data-slug="${savedSlug}"]`);
  //     if (boxElement) {
  //       boxElement.scrollIntoView({ behavior: "smooth" });
  //     }

  //     localStorage.removeItem("scrollBoxSlug");
  //     localStorage.removeItem("scrollBoxList");
  //   }
  // }, []);

  // const handleClick = (slug, listName) => {
  //   localStorage.setItem("scrollBoxSlug", slug);
  //   localStorage.setItem("scrollBoxList", listName);
  // };

  return (
    <>
      <Header />
      <section className="voting_sec1">
        <CommonBanner
          imgSrc={windowWidth > 600 ? finalist2024Banner : mbFinalist2024Banner}
          title={``}
        />
      </section>

      <section className="voting_sec2">
        <div className="my_container">
          <div className="sec1_wrapper">
            <div className="text_container">
              <h2 className="section_heading voting_heading">
                VOTING IS NOW OPEN!
              </h2>
              <p className="section_desc voting_desc">
                Meet our finalists and vote now for the next generation of
                Indian beauty entrepreneurs.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="voting_sec4" id="grow">
        <div className="my_container voting_container">
          <div className="content_wrapper">
            <h4 className="voting_heading">GROW award</h4>
            <h6 className="voting_text">
              In-market beauty concepts in the categories of skin care, make up,
              hair care, personal fragrance and home fragrance.
            </h6>
          </div>
          <div className="row grow_row">{growList}</div>
        </div>
      </section>

      <section className="voting_sec3" id="imagine">
        <div className="my_container voting_container">
          <div className="content_wrapper">
            <h4 className="voting_heading">imagine award</h4>
            <h6 className="voting_text">
              Pre-launch beauty concepts in the categories of skin care, make
              up, hair care, personal fragrance and home fragrance.
            </h6>
          </div>
          <div className="row imagine_row">{imagineList}</div>
        </div>
      </section>

      <section className="voting_sec5" id="create">
        <div className="my_container voting_container">
          <div className="content_wrapper">
            <h4 className="voting_heading">create award</h4>
            <h6 className="voting_text">
              The next generation of Creative Talent submitting work around the
              theme of Supercharged Futures.
            </h6>
          </div>
          <div className="row create_row">{createList}</div>
        </div>
      </section>

      <section className="voting_ligibox_wrapper">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>

      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <div className="emailid_modal" style={{ backgroundColor: modalColor }}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {/* <h4 className="email_heading">ENTER EMAIL</h4> */}
            <h4 className="email_heading">VOTE NOW</h4>
            <p className="desc">
              {/* Please Enter Your Email Adress To Vote A Deserving Candidate */}
              PLEASE ENTER YOUR EMAIL ADDRESS TO CONFIRM YOUR VOTE
            </p>
            <form onSubmit={handleotpverify}>
              <div className="form_wrapper">
                <div className="form_field_container">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {formError.email && (
                    <p className="error_message">{formError.email}</p>
                  )}
                </div>
                <div className="consent_checkbox_wrapper">
                  <input
                    type="checkbox"
                    id="updateConsent"
                    name="updateConsent"
                    className="consent_input"
                    onClick={handleterm1}
                  />
                  <label for="updateConsent" className="consent_label">
                    {/* <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span> */}
                    <span>
                      {" "}
                      By checking this box and clicking Sign Up, I agree to the{" "}
                      <Link
                        to={termsandconditionsPDF}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link to={privacyandcookiepolicyroute}>
                        Privacy Policy
                      </Link>
                      .
                    </span>
                  </label>
                  {formError.term1 && (
                    <p className="error_message">
                      <strong>{formError.term1}</strong>
                    </p>
                  )}
                </div>
                <div className="btn_wrapper">
                  <button
                    className="form_submit_btn  project-button"
                    type="submit"
                  >
                    {loading ? "Loading.." : "SUBMIT"}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </div>

        {/* <div className="otp_modal">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h4 className="section_heading text-center">Enter OTP</h4>
            <p className="desc">
              Please enter the code sent to your account email address.
              <br />
              <br />
              Do not close this page. Please check your inbox for the OTP to
              validate your account. If you do not see it, please check your
              spam folder. Any issues? Please contact us at{" "}
              <a href="mailto:applications@beautyandyouawards.com">
                applications@beautyandyouawards.com
              </a>
              .
            </p>
            <button className="form_submit_btn otp_verify_btn">VERIFY</button>
            <p className="section_desc resend_otp_text"></p>
            <p className="section_desc otp_sent_text">
              OTP is sent to registered email id
            </p>
          </Modal.Body>
        </div> */}
      </Modal>
      <Footer />
    </>
  );
};

export default Voting;
