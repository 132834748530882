import {
  aboutUsURL,
  applicationcriteria,
  masterClass,
  judges,
  twentThreeWinnersURL,
  finalist2024URL,
} from "../helpers/constant-words";
import { images, whitecommonstrip } from "../images";

export const bannerData = [
  {
    size: "full",
    type: "img",
    position: "top",
    class: "banner07",
    bgImg: images.banner10.image,
    mobBgImg: images.banner10mb.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "VOTING IS NOW OPEN!",
    mobTitle: "VOTING IS NOW OPEN!",
    desc: "Meet our finalists and vote now for the next generation of Indian beauty entrepreneurs.",
    ctaText: "VOTE NOW",
    ctaURL: finalist2024URL,
  },
  {
    type: "img",
    size: "full",
    class: "banner01",
    bgImg: images.banner06.image,
    mobBgImg: images.banner06mb.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "THE FIRST OF <br />ITS KIND BEAUTY PROGRAM",
    mobTitle: "THE FIRST OF ITS KIND BEAUTY PROGRAM",
    desc: "Fueling the next generation of beauty entrepreneurs.",
    ctaText: "LEARN MORE",
    ctaURL: aboutUsURL,
  },
  {
    type: "img",
    size: "full",
    position: "center",
    class: "banner02",
    bgImg: images.banner01.image,
    mobBgImg: images.banner01mb.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "BEAUTY&YOU INDIA 2024",
    desc: "A first of its kind program for beauty start-ups <br class='mobile_break_disable' />that are focused on the Indian market. Created by The <br class='mobile_break_disable' />Estée Lauder Companies’ New Incubation Ventures.",
    ctaText: "LEARN MORE",
    ctaURL: aboutUsURL,
  },
  {
    size: "full",
    type: "split",
    class: "banner03",
    leftImg: images.banner0701.image,
    mobLeftImg: images.banner0701mb.image,
    rightImg: images.banner0702.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "BEAUTY&YOU INDIA 2024",
    desc: "Bring your ideas to life. Created by The Estée Lauder Companies New Incubation Ventures and launched with lead partner Nykaa.",
    ctaText: "LEARN MORE",
    ctaURL: applicationcriteria,
  },
  {
    type: "img",
    size: "full",
    position: "center",
    class: "banner04",
    bgImg: images.banner08.image,
    mobBgImg: images.banner08mb.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "The judges",
    desc: "Meet our 2024 judges",
    ctaText: "LEARN MORE",
    ctaURL: judges,
  },
  // {
  //   type: "img",
  //   size: "full",
  //   position: "center",
  //   class: "banner05",
  //   bgImg: images.banner04.image,
  //   mobBgImg: images.banner04mb.image,
  //   featuredText: "Created by",
  //   featuredImg: whitecommonstrip,
  //   title: "TALKING BEAUTY <br />& BUSINESS <br />WITH ",
  //   titleIcon: bofIcon,
  //   desc: "In Conversation With Imran Ahmed, Founder & CEO <br class='mobile_break_disable' />at the Business Of Fashion and Priya Rao, Executive <br class='mobile_break_disable' />Editor at the Business Of Beauty at BOF.",
  //   ctaText: "LEARN MORE",
  //   ctaURL: businessOfFashionURL,
  // },
  {
    type: "img",
    size: "full",
    position: "center",
    class: "banner09",
    bgImg: images.banner09.image,
    mobBgImg: images.banner09mb.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "2024 MASTERCLASSES <br />ARE NOW LIVE!",
    desc: "Access our masterclasses featuring iconic beauty and fashion founders, creators and brand experts.",
    ctaText: "LEARN MORE",
    ctaURL: masterClass,
  },
  // {
  //   type: "img",
  //   size: "full",
  //   position: "top",
  //   class: "banner06",
  //   bgImg: images.banner0501.image,
  //   mobBgImg: images.banner05mb.image,
  //   featuredText: "Created by",
  //   featuredImg: whitecommonstrip,
  //   title: "MEET THE <br />2023 WINNERS",
  //   desc: "The next generation of Indian beauty <br />entrepreneurs.",
  //   ctaText: "LEARN MORE",
  //   ctaURL: twentThreeWinnersURL,
  // },
  {
    size: "full",
    type: "img",
    position: "top",
    class: "banner07",
    bgImg: images.banner05new.image,
    mobBgImg: images.banner05newmb.image,
    featuredText: "Created by",
    featuredImg: whitecommonstrip,
    title: "MEET THE <br />2023 WINNERS",
    mobTitle: "MEET THE <br />2023 WINNERS",
    desc: "The next generation of Indian beauty <br />entrepreneurs.",
    ctaText: "LEARN MORE",
    ctaURL: twentThreeWinnersURL,
  },
];

export const quoteArr = [
  {
    img: images.rohnavaziralliportrait.image,
    imgAlt: images.rohnavaziralliportrait.alt,
    quote:
      "“BEAUTY&YOU has evolved into one of the premier beauty-focused programs in India, with a singular mission to empower young ideas, businesses, and communities to innovate and create. Witnessing stellar ideas and meeting passionate founders in the last two editions has filled me with great hope for this unique ecosystem that is shaping India’s entrepreneurial future. As we embark on the third edition, I look forward to discovering more visionary ideas and supporting them with the unique insights of Estée Lauder’s beauty legacy.”",
    name: "ROHAN VAZIRALLI",
    designation: "GENERAL MANAGER, ELCA COSMETICS <br />PRIVATE LIMITED",
  },
  // {
  //   img: images.shanarandhavaportrait.image,
  //   imgAlt: images.shanarandhavaportrait.alt,
  //   quote:
  //     "“Our ambition is to partner with creative disruptors who are building experiential beauty brands that speaks to the values and passions of today’s (and tomorrow’s) consumers.”",
  //   name: "Shana Randhava",
  //   designation:
  //     "SENIOR VICE PRESIDENT, NEW INCUBATION VENTURES<br />THE ESTÉE LAUDER COMPANIES",
  // },
  // {
  //   img: images.gauravguptaportrait.image,
  //   imgAlt: images.gauravguptaportrait.alt,
  //   quote:
  //     "“It is an absolute delight to return as a jury member for the BEAUTY&YOU program's third edition. Last year, my first as a juror, I was profoundly amazed by the immense talent and creativity exhibited by the participants. The innovation and artistic expression I witnessed not only inspired me but also highlighted the boundless potential within the beauty and fashion industries. I am eagerly looking forward to being further inspired this year as we continue to discover and nurture the incredible talents that shape the future of beauty.”",
  //   name: "gaurav gupta",
  //   designation: "COUTURIER, ARTIST AND DESIGNER",
  // },
  {
    img: images.anchitpotrait.image,
    imgAlt: images.anchitpotrait.alt,
    quote:
      "“As Nykaa continues to play its role in nurturing India’s beauty ecosystem, the global recognition of India’s potential as both a powerful consumer market and entrepreneurial hub is more evident now than ever before. In its third edition, BEAUTY&YOU is more than just a platform—it's a gateway for Indian entrepreneurs to showcase their creativity and rigor on a global stage and we are excited to be part of their future.”",
    name: "Anchit Nayar",
    designation: "EXECUTIVE DIRECTOR & CEO, NYKAA BEAUTY",
  },
  {
    img: images.katrinakaifpotrait.image,
    imgAlt: images.katrinakaifpotrait.alt,
    quote:
      "“Participating in Beauty&YOU since its first edition has been an immensely rewarding experience, particularly in seeing its transformative impact on the Indian beauty industry . The program enhances the trajectory of emerging brands by providing a platform that fosters growth, innovation, and visibility. In my role as the co-founder of Kay Beauty, I have a deep appreciation for such platforms that support entrepreneurs in their journey. As we enter the third edition, I am as excited and committed as ever to discover and support brands that are shaping the future of beauty in India.”",
    name: "katrina kaif",
    designation: "ACTOR & CO-FOUNDER, KAY BEAUTY",
  },
  // {
  //   img: images.anaitashroffportrait.image,
  //   imgAlt: images.anaitashroffportrait.alt,
  //   quote:
  //     "“I am thrilled to be part of the Beauty&You program for its third edition. Having witnessed it since inception in 2022 and growth firsthand, it's truly inspiring to see how this platform has evolved into a beacon of innovation and empowerment for young brands and entrepreneurs. Each year, the creativity and determination displayed by the participants not only reinforce the vibrancy of the beauty industry but also drive us towards a more dynamic and inclusive future. I am eagerly looking forward to discovering new talents and continuing to contribute to this extraordinary journey of nurturing groundbreaking ideas in beauty.”",
  //   name: "Anaita Shroff Adajania",
  //   designation: "STYLIST, CREATIVE DIRECTOR & FOUNDER, STYLE CELL",
  // },
  // {
  //   img: images.divyaMalpani.image,
  //   imgAlt: images.anaitashroffportrait.alt,
  //   quote:
  //     "“Participating and winning in the GROW category of the first edition of Estée Lauder and Nykaa’s BEAUTY&YOU Awards gave my brand a stamp of recognition and approval.”",
  //   name: "DIVYA MALPANI",
  //   designation: "2022 WINNER",
  // },
  // {
  //   img: images.khanakGupta.image,
  //   imgAlt: images.khanakGupta.alt,
  //   quote:
  //     "“BEAUTY&YOU gave me the confidence to look inwards and innovate upon what I could take from India to the world.”",
  //   name: "KHANAK GUPTA",
  //   designation: "2023 WINNER",
  // },
];
export const mediaArr = [
  {
    img: images.theeconomictimesblack.image,
    link: "https://economictimes.indiatimes.com/tech/technology/nykaa-estee-lauder-launches-incubation-programme-beautyyou-india/articleshow/93012028.cms",
  },
  {
    img: images.voguebusinessblack.image,
    link: "https://www.voguebusiness.com/beauty/the-next-breakthrough-beauty-brand-estee-lauder-says-it-could-come-from-india",
  },
  {
    img: images.fashionnetworkblack.image,
    link: "https://ww.fashionnetwork.com/news/Estee-lauder-and-nykaa-launch-incubator-for-young-indian-beauty-brands,1426603.html",
  },
  {
    img: images.thevoiceoffashionblack.image,
    link: "https://thevoiceoffashion.com/centrestage/news/the-week-in-fashion-couture-celebrities-collaborations-5120",
  },
  {
    img: images.inc42black.image,
    link: "https://inc42.com/buzz/nykaa-estee-lauder-bring-incubation-programme-to-help-beauty-entrepreneurs-scale/",
  },
  {
    img: images.forbesblack.image,
    link: "https://www.forbesindia.com/article/startups/our-idea-is-to-find-the-next-esté-e-lauder-nykaa/78565/1",
  },
  {
    img: images.wwdblack.image,
    link: "https://wwd.com/beauty-industry-news/beauty-features/estee-lauder-nykaa-team-to-seek-next-global-beauty-brand-in-india-1235261945/",
  },
  {
    img: images.vogueblack.image,
    link: "https://www.vogue.in/beauty/content/exclusive-first-beauty-start-up-funding-program-without-sharing-equity-estee-lauder-x-nykaa-opens-for-applications-today",
  },
];
